(function() {
    $(".sega__header_container_hamburger_line").on("click", function() {
        $(".sega__header_container_hamburger_line").toggleClass('sega__header_container_hamburger_line--open');
        $(".sega__header_container_box_menu").toggleClass('sega__header_container_box_menu--active');
        $(".sega__header_container_box_more").toggleClass('sega__header_container_box_more--active');
        $(".sega__header_container_top_contact").toggleClass('sega__header_container_top_contact--active');
        $("body").toggleClass('no-scroll');
        if (window.matchMedia("(max-width:980px)").matches) {
            $(".sega__searchPopup").removeClass('sega__searchPopup--active');
        }
    });

    $(".login_button").on("click", function() {
        $(".login_button").toggleClass("active");
        $(".sega__header_container_login").toggleClass("active");
    });


    $(".login_button").on("click", function() {
        $(".sega__loginPopup").toggleClass("sega__loginPopup--active");
    });
    $(".closeLoginPopup").on("click", function() {
        $(".sega__loginPopup").removeClass("sega__loginPopup--active");
    });


    $("#other-check-input").on("click", function () {
        $(".other-input").toggleClass("other-input--active");
    });

    $("#other-check-input-rwd").on("click", function () {
        $(".other-input-rwd").toggleClass("other-input-rwd--active");
    });

    $("#human-radio").on("click", function () {
        $(".input__button_red").toggleClass("active");
    });

    $('.sega__loginPopup_login').mousedown(function(e) {
        var clicked = $(e.target);
        if (clicked.is('.sega__loginPopup_login_container') ||
            clicked.parents().is('.sega__loginPopup_login_container')) {
            return;
        } else {
            $(".sega__loginPopup").removeClass("sega__loginPopup--active");
        }
    });

    $('.sega__enquiryProduct_box_button').on("click", function() {
        if ($('.sega__enquiryProduct_box').val().length > 0 || $('.sega__enquiryProduct_box_button').is(':focus')) {
            $(this).parent().addClass('sega__enquiryProduct_box--remove');

        }
    });
})();

(function() {
    $(".panel-search").on("click", function() {
        $(".sega__productsTab_panel_box_search").toggleClass('sega__productsTab_panel_box_search--active');
        $(".panel-search").toggleClass('panel-search--active');
        $(".panel-sort").removeClass('panel-sort--active');
        $(".sega__productsTab_panel_box_sort").removeClass('sega__productsTab_panel_box_sort--active');
    });
    $(".panel-sort").on("click", function() {
        $(".sega__productsTab_panel_box_search").removeClass('sega__productsTab_panel_box_search--active');
        $(".panel-sort").toggleClass('panel-sort--active');
        $(".panel-search").removeClass('panel-search--active');
        $(".sega__productsTab_panel_box_sort").toggleClass('sega__productsTab_panel_box_sort--active');
    });
})();

(function() {
    $(".hide-button").on("click", function() {
        $(".sega__basketScroll_container_cost").toggleClass('sega__basketScroll_container_cost--hide');
        $(".sega__basketScroll_container_quantity").toggleClass('sega__basketScroll_container_quantity--hide');
        $(".sega__basketScroll_container_add").toggleClass('sega__basketScroll_container_add--hide');
        $(".hide-button").toggleClass('hide-button--hide');
    });
})();

(function() {
    $(".sega__productsTab_content_menu_box_titleGame").on("click", function() {
        $(".sega__productsTab_content_menu_box_titleGame").toggleClass('open');
        $(".sega__productsTab_content_menu_box_listGame").toggleClass('open');
    });

    $(".sega__productsTab_content_menu_box_titleManufactured").on("click", function() {
        $(".sega__productsTab_content_menu_box_titleManufactured").toggleClass('open');
        $(".sega__productsTab_content_menu_box_listManufactured").toggleClass('open');
    });

    $(".sega__productsTab_content_menu_box_titleCategory").on("click", function() {
        $(".sega__productsTab_content_menu_box_titleCategory").toggleClass('open');
        $(".sega__productsTab_content_menu_box_listCategory").toggleClass('open');
    });

    $(".sega__productsTab_content_menu_box_titleNo").on("click", function() {
        $(".sega__productsTab_content_menu_box_titleNo").toggleClass('open');
        $(".sega__productsTab_content_menu_box_listNo").toggleClass('open');
    });

})();

(function() {
    $(".more-information-cookie").on("click", function() {
        $(".sega__cookie_content_container_explanation").toggleClass('sega__cookie_content_container_explanation--active');
        $(".more-information-cookie").toggleClass('more-information-cookie--open');
    });
})();

(function() {
    $("#cookie-settings-button").on("click", function() {
        $(".sega__cookie_content_container_settings").toggleClass('sega__cookie_content_container_settings--active');
        $(".cookie-settings-button").toggleClass('cookie-settings-button--open');
    });
})();

(function() {
    $(".search-bar-button").on("click", function() {
        $(".sega__searchPopup").toggleClass('sega__searchPopup--active');
    });
})();

(function() {
    $(".close-search").on("click", function(e) {
        e.preventDefault();
        $(".sega__searchPopup").removeClass('sega__searchPopup--active');
    });
})();

// Open filters mobile view
(function(){
    var mobileViewport = window.matchMedia("screen and (max-width: 800px)");

    if(!mobileViewport) {
        return
    }

    var openFilterBtn = $('.sega__productsTab_content_openFilters');
    var sidebar = openFilterBtn.parent().find('.sega__productsTab_content_menu');
    var closeFilterBtn = sidebar.find('.sega__productsTab_content_closeFilters');

    if(openFilterBtn) {
        openFilterBtn.on('click', function (e) {
            e.preventDefault();
            sidebar.addClass('is-active');
            $("body").addClass('no-scroll');

            setTimeout(function() {
                sidebar.addClass('is-showing');
            }, 250);
        });

        closeFilterBtn.on('click', function (e) {
            e.preventDefault();
            sidebar.removeClass('is-showing');
            $("body").removeClass('no-scroll');

            setTimeout(function() {
                sidebar.removeClass('is-active');
            }, 250);
        });
    }
})();
