(function () {
    $('.responsive').slick({
        // dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1224,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        }, {
            breakpoint: 930,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }]
    });
})();

(function () {
    $('.responsive_products_small').slick({
        // dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 930,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
            }
        }, {
            breakpoint: 350,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }]
    });
})();

(function () {
    $('.product_responsiveRight').slick({
        // dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1224,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        }, {
            breakpoint: 940,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: false,
                infinite: true,
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }, {
            breakpoint: 440,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }]
    });
})();

(function () {
    var $imagesSliderMax = $(".sega__product_container_boxLeft_galleryMax_slider_images>div"),
        $thumbnailsSliderMax = $(".sega__product_container_boxLeft_galleryMax_slider_thumbnails>div");

    var $imagesSliderMin = $(".sega__product_container_boxLeft_galleryMin_slider_images>div"),
        $thumbnailsSliderMin = $(".sega__product_container_boxLeft_galleryMin_slider_thumbnails>div");

    $imagesSliderMax.slick({
        speed:300,
        slidesToShow:1,
        slidesToScroll:1,
        cssEase:'linear',
        fade:true,
        verticalSwiping:false,
        draggable:false,
        asNavFor:".sega__product_container_boxLeft_galleryMax_slider_thumbnails>div",
        prevArrow:'.sega__product_container_boxLeft_galleryMax_slider_images .prev-arrow',
        nextArrow:'.sega__product_container_boxLeft_galleryMax_slider_images .next-arrow'
    });

    $imagesSliderMin.slick({
        speed:300,
        slidesToShow:1,
        slidesToScroll:1,
        cssEase:'linear',
        verticalSwiping:false,
        fade:true,
        draggable:false,
        asNavFor:".sega__product_container_boxLeft_galleryMin_slider_thumbnails>div",
        prevArrow:'.sega__product_container_boxLeft_galleryMin_slider_images .prev-arrow',
        nextArrow:'.sega__product_container_boxLeft_galleryMin_slider_images .next-arrow'
    });

    $('.products-slider .slick-track').addClass('products-slider');
    $(".slick-track:not('.products-slider')").lightGallery({
        pager: true,
    });

    $thumbnailsSliderMax.slick({
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: 'linear',
        verticalSwiping:false,
        centerMode: false,
        draggable: false,
        asNavFor: ".sega__product_container_boxLeft_galleryMax_slider .sega__product_container_boxLeft_galleryMax_slider_images>div",
        prevArrow: '.sega__product_container_boxLeft_galleryMax_slider_thumbnails .prev-arrow',
        nextArrow: '.sega__product_container_boxLeft_galleryMax_slider_thumbnails .next-arrow',
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $thumbnailsSliderMin.slick({
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: 'linear',
        centerMode: false,
        verticalSwiping:false,
        draggable: false,
        asNavFor: ".sega__product_container_boxLeft_galleryMin_slider .sega__product_container_boxLeft_galleryMin_slider_images>div",
        prevArrow: '.sega__product_container_boxLeft_galleryMin_slider_thumbnails .prev-arrow',
        nextArrow: '.sega__product_container_boxLeft_galleryMin_slider_thumbnails .next-arrow',
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });


    var phoneViewport = window.matchMedia("screen and (max-width: 576px)");
    var tabletViewport = window.matchMedia("screen and (max-width: 720px)");

    var slickSlidesWrapper = $('.sega__product_container_boxLeft_galleryMax_slider_thumbnails');
    var slickSlides = slickSlidesWrapper.find('.slick-track .item');

    if (slickSlides) {
        if(phoneViewport.matches) {
            if(slickSlides.length <= 2) {
                slickSlidesWrapper.find('.slick-arrow').css('display', 'none');
            }
        } else if(tabletViewport.matches) {
            if(slickSlides.length <= 3) {
                slickSlidesWrapper.find('.slick-arrow').css('display', 'none');
            }
        } else {
            if(slickSlides.length <= 4) {
                slickSlidesWrapper.find('.slick-arrow').css('display', 'none');
            }
        }
    }
})();
