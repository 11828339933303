(function() {
    $('#slide_down').on("click", function() {
        $("html, body").animate({
            scrollTop: $("#slider-gallery").offset().top - 250
        }, 10);
    });

    $('.dropdown-el').on("click", function() {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass('expanded');
        $('#'+$(e.target).attr('for')).prop('checked',true);
    });
    $(document).on("click", function() {
        $('.dropdown-el').removeClass('expanded');
    });
})();
